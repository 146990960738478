import React from "react"
import { Card, CardImg, CardImgOverlay, CardBody, CardText, CardTitle } from "src/ui"
import { InternalLink } from "../InternalLink"
import useTranslations from "../useTranslations"

export interface OfferProps {
  imageUrl: string
  title: string
}

export const Offer: React.FC<OfferProps> = ({ imageUrl, title }) => {
  const buttonText = useTranslations("more")
  return (
    <div className="offer-item card-container overflow-hidden">
      <Card className="text-white">
        <CardImg width="100%" src={imageUrl} alt={title} />
        <CardImgOverlay className="d-flex align-items-end card-img-overlay-gradient">
          <CardBody>
            <CardTitle tag="h4" className="mb-0">
              <span className="border-secondary border-bottom">{title}</span>
            </CardTitle>
            <CardText className="mb-0 pt-3 text-uppercase font-weight-bold small text-white">{buttonText}</CardText>

            {/* pageId is the relative folder name path from /content */}
            <InternalLink pageId="richieste-prenotazione/offerte" className="stretched-link" children="" />
          </CardBody>
        </CardImgOverlay>
      </Card>
    </div>
  )
}
